<template>
  <div class="usertable-container">
    <div class="tooltip">
      <el-input
        v-model="queryKey"
        class="tip-item tip-input"
        placeholder="输入编号昵称（模糊匹配）"
        clearable
        size="mini"
        @change="handleChange"
      />
      <el-button class="item-btn" type="primary" size="mini" @click="submit">
        检索
      </el-button>
    </div>
    <el-table
      :stripe="true"
      class="usertable"
      :data="tableData"
      size="mini"
      style="width: 100%"
    >
      <el-table-column
        type="index"
        :index="handleIndex"
        label="序号"
        align="center"
      />
      <el-table-column prop="number" label="编号" align="center" />
      <el-table-column prop="nickName" label="昵称" align="center" />
      <el-table-column
        v-if="type === 0"
        prop="weighted"
        :min-width="70"
        label="加权次数"
        align="center"
      />
      <el-table-column
        v-if="type === 1"
        :min-width="130"
        prop="prizeName"
        label="奖品名称"
      />
    </el-table>
    <el-pagination
      class="table-pagination"
      size="mini"
      :current-page="currentPage"
      layout="total, prev, pager, next"
      :total="total"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<style lang="scss" scoped>
.usertable-container {
  background-color: #fff !important;
}
.tooltip {
  background-color: #fff;
  display: flex;
  margin-left: 0;
  padding: 0 20px;
  justify-content: flex-start;
  box-sizing: border-box;
  margin-bottom: 20px;
  .tip-item {
    margin: 0 10px 0 -20px;
  }
}
</style>
<style lang="scss">
.usertable-container {
  .el-button--mini,
  .el-input__inner {
    font-size: 9px;
  }

  // 分页
  .table-pagination {
    margin: 0 auto;
    .el-pager li {
      font-size: 8px;
      min-width: 15px;
    }
    &.el-pagination button,
    &.el-pagination span:not([class*='suffix']) {
      font-size: 8px;
    }
    &.el-pagination .btn-next .el-icon,
    &.el-pagination .btn-prev .el-icon {
      font-size: 8px;
    }
  }
}
.usertable.el-table {
  background-color: #fff;
  th > .cell {
    padding-bottom: 0px;
    color: #666;
    font-size: 9px;
  }
  th > .cell {
    padding: 0;
  }
  .el-table__empty-text {
    font-size: 9px;
  }
  .cell {
    font-size: 9px;
    line-height: 13px;
    padding: 0 1px;
  }
  td,
  th.is-leaf {
    padding: 5px 0;
  }
}
</style>
<script>
import usertable from './index.js';
export default usertable;
</script>
