import { getUrlKey } from '@/apis/utils';
import usertable from '../components/usertable/index.vue';
import { getJoinUser, getLuckUser } from '@/apis/activity';
export default {
  name: 'selectall',
  components: {
    usertable
  },
  data() {
    return {
      // 表格数据，参与名单
      alluser: [
        // {
        //   number: '5601',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // },
        // {
        //   number: '56031',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // },
        // {
        //   number: '56021',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // },
        // {
        //   number: '56301',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // },
        // {
        //   number: '56901',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // },
        // {
        //   number: '5601',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // }
      ],
      // 表格数据，中奖名单
      luckyuser: [
        // {
        //   number: '56301',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // },
        // {
        //   number: '56901',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // },
        // {
        //   number: '5601',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // }
      ],
      activeTabName: 'alluser', // alluser参与人员  luckyuser中奖用户
      scrollMax: 300,
      navClass: '',
      acName: '', // 活动名称
      activeId: 0, // 活动ID
      prizeId: 0, // 奖品ID
      openStatus: true, // 是否开奖
      promiseTimer: '', // 倒计时定时器
      timeInfo: {}, // 当开奖时间比当前时间晚超 24 小时的时间信息
      allPage: 1, // 参与名单页码
      allSize: 10, // 参与名单每页大小
      allTotal: 0, // 参与名单总数
      luckPage: 1, // 中奖名单页码
      luckSize: 10, // 中奖名单每页大小
      luckTotal: 0 // 中奖名单总数
    };
  },
  created() {
    // 从url中获取活动相关参数
    const activeId = getUrlKey('activeId');
    const acName = getUrlKey('acName');
    // console.log('activeId=' + activeId)
    // console.log('acName=' + acName)
    this.activeId = activeId;
    this.acName = acName;
  },
  mounted() {
    // 设置滚动渐变效果
    const stepTotal = 30;
    const stepHeight = this.scrollMax / stepTotal;
    this.$el.addEventListener('scroll', e => {
      if (this.$el.scrollTop >= this.scrollMax - 2) {
        this.navClass = `step${stepTotal - 1}`;
      } else {
        this.navClass = `step${Math.floor(
          stepTotal - (this.scrollMax - this.$el.scrollTop) / stepHeight
        )}`;
      }
    });
  },
  methods: {
    goBack() {
      // 返回上一页
      this.$router.go(-1);
    },
    getLuckyuser(key, pageNo) {
      const _this = this;
      // 点击中奖用户的搜索时
      // console.log(`-=-点击中奖用户的搜索时=-${val}`)
      getLuckUser({
        activityId: _this.activeId,
        pageNo: pageNo,
        pageSize: _this.luckSize,
        searchKey: key
      })
        .then(res => {
          let data = _this.handleResponse(res);
          if (data.code === 200) {
            const totalCount = data.total;
            _this.luckTotal = totalCount;
            data = data.data;
            _this.luckyuser.length = 0;
            _this.luckyuser = data;
          }
        })
        .catch(err => {
          this.$message({
            message: '查询中奖名单出错！！！',
            customClass: 'ballMsg',
            type: 'error',
            duration: 3000,
            center: true,
            offset: 300
          });
          console.log(err);
        });
    },
    getAllUser(key, pageNo) {
      const _this = this;
      // 点击全部参与用户的搜索时
      // console.log(`-=-点击全部参与用户的搜索时=-${val}`)
      getJoinUser({
        activityId: _this.activeId,
        pageNo: pageNo,
        pageSize: _this.allSize,
        searchKey: key
      })
        .then(res => {
          let data = this.handleResponse(res);
          if (data.code === 200) {
            const totalCount = data.total;
            _this.allTotal = totalCount;
            data = data.data;
            _this.alluser.length = 0;
            _this.alluser = data;
          }
        })
        .catch(err => {
          this.$message({
            message: '查询参与名单出错！！！',
            customClass: 'ballMsg',
            type: 'error',
            duration: 3000,
            center: true,
            offset: 300
          });
          console.log(err);
        });
    }
  }
};
