export default {
  name: 'usertable',
  props: {
    tableData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    type: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      queryKey: '', // 搜索内容
      currentPage: 1 // 当前页码
    };
  },
  created() {
    this.$emit('submit', undefined, this.currentPage);
  },
  mounted() {
    // console.log(this.tableData)
  },
  methods: {
    // 失去焦点，按下回车时触发
    handleChange(val) {
      this.currentPage = 1;
      this.$emit('submit', this.queryKey, 1);
    },
    submit() {
      // 点击搜索后，让分页的下标回到1上
      this.currentPage = 1;
      // 搜索
      this.$emit('submit', this.queryKey, 1);
    },
    // 页数改变
    handleCurrentChange(val) {
      // 改变默认页数
      this.currentPage = val;
      if (this.queryKey !== '') {
        this.$emit('submit', this.queryKey, this.currentPage);
      } else {
        this.$emit('submit', undefined, this.currentPage);
      }
    },
    // 处理序号
    handleIndex(index) {
      return index + 1 + 10 * (this.currentPage - 1);
    }
  }
};
