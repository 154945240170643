export const getUrlKey = name => {
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
        location.href
      ) || ['', ''])[1].replace(/\+/g, '%20')
    ) || null
  );
};
/**
 * @param {obj} Object 一个对象
 * @description 在num的范围内生成n个不重复的数(不包含num)
 */
export const randomNum = (num, n) => {
  num = num - 1;
  if (typeof num !== 'number' || typeof n !== 'number') return false; // 对象检测
  const aNum = [];
  if (num <= n) {
    for (let j = 0; j < num; j++) {
      aNum.push(j);
    }
    return aNum;
  } else {
    // 如果n大于num就生成0到num-1的每一个数
    while (aNum.length < n) {
      const random = Math.round(Math.random() * num);
      if (aNum.indexOf(random) === -1) {
        aNum.push(random);
      }
    }
    return aNum;
  }
};

/**
 * 获取东八区当地时间
 * @returns {Date}
 */
export const getGMT8Date = () => {
  return new Date(
    new Date().getTime() +
      new Date().getTimezoneOffset() * 60 * 1000 +
      8 * 60 * 60 * 1000
  );
};
