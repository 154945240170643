<template>
  <div class="container">
    <div class="header-containter">
      <div class="header-bg" />
      <div class="navbar" :class="navClass">
        <div class="nav-title">
          <i class="go-back-btn el-icon-arrow-left" @click="goBack" />
        </div>
        <div class="nav-acname">
          用户参与详情
        </div>
        <div class="nav-btn-containter">
          <!-- 中奖查询 -->
        </div>
      </div>
      <header id="header" class="header">
        <span class="header-desc">{{ acName }}</span>
      </header>
    </div>
    <div class="list-containter">
      <div class="list-content">
        <div class="item table">
          <el-tabs v-model="activeTabName">
            <el-tab-pane label="参与名单" name="alluser">
              <usertable
                :table-data="alluser"
                :type="0"
                :total="allTotal"
                @submit="getAllUser"
              />
            </el-tab-pane>
            <el-tab-pane label="中奖名单" name="luckuser">
              <usertable
                :table-data="luckyuser"
                :type="1"
                :total="luckTotal"
                @submit="getLuckyuser"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$headerHeight: 200px;
$navHeight: 40px;
$navFontSize: 12px;
$headerDescFontSize: 22px;

.container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  // overflow: hidden;
  overflow-y: scroll;
  background: #07090d;
  // background-color: #f4f4f4;
  .header-containter {
    background: url('../../../assets/prizelist/header-bg.png');
    background-size: auto 100%;
    background-position: center center;
    position: relative;
    // overflow: hidden;
    height: $headerHeight;
  }
  .list-containter {
    width: 100%;
    position: relative;
    margin: -50px auto auto auto;
    padding: 0 10px 10px;
    box-sizing: border-box;
    background: #07090d;
    // min-height: 90vh;
  }
}
.header-containter {
  .header-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .navbar {
    z-index: 9;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    height: $navHeight;
    position: fixed;
    display: flex;
    color: #fff;
    justify-content: space-between;
  }
  .nav-acname {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-size: $navFontSize;
    // color: rgba(255, 255, 255, 0);
    // opacity: 0;
  }
  $stepTotal: 30;
  @mixin steploop($i) {
    .navbar.step#{$i} {
      $op: $i / ($stepTotal - 1);
      background-color: rgba(6, 12, 12, round($op * 10000) / 10000);
    }
  }
  @for $i from 0 through $stepTotal {
    @include steploop($i);
  }
  .header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $navHeight 8% 10px 8%;
    box-sizing: border-box;
    position: relative;
    .header-desc {
      margin-top: -10%;
      text-align: center;
      font-family: PingFangSC-Regular;
      font-size: $headerDescFontSize;
      color: #fff;
      font-weight: bold;
      font-family: serif;
    }
  }
}
.navbar {
  .nav-name {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: $navFontSize;
    width: 100%;
  }
  .nav-title,
  .nav-btn-containter {
    width: 120px;
    height: 100%;
    display: inline-block;
  }
  .nav-title {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
  }
  .go-back-btn {
    font-size: $navFontSize;
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    &:hover {
      color: #1ab4ab;
    }
  }
}
// 奖品元素
.list-containter {
  .list-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .item {
    width: 100%;
    // margin-bottom: 50px;
    position: relative;
    background-color: #fff;
    margin: 0 10px;
    padding: 20px;
    box-sizing: border-box;
    &.table {
      margin-bottom: 40px;
    }
  }
}
.item {
  .item-img {
    text-align: center;
    margin-bottom: 8px;
    img {
      height: 180px;
    }
  }
  .prize-name {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 24px;
    font-weight: bold;
    font-family: PingFangSC-Semibold;
  }
  .prize-balance {
    margin-left: 5px;
    color: #1ab4ab;
  }
  .prize-count {
    margin-left: 10px;
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
      width: 20px;
    }
  }
  .timer {
    margin-top: 15px;
    .countdown-time {
      font-family: PingFangSC-Regular;
      display: flex;
      align-items: center;
      font-size: 20px;
      line-height: 20px;
      color: #333;
    }
    .time-item {
      font-weight: bold;
      color: #1ab4ab;
    }
    .time-split {
      margin: 0 4px;
      line-height: 16px;
    }
  }
  .provider {
    // position: absolute;
    // bottom: 30px;
    margin-top: 10px;
    font-family: PingFangSC-Regular;
    font-size: 22px;
    color: #888;
    .sponsor {
      color: #1ab4ab;
      display: inline-block;
      margin-right: 4px;
    }
  }
  .detail-btn {
    position: absolute;
    bottom: 32px;
    right: 20px;
    border-radius: 4px;
    width: 100px;
    line-height: 40px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    background-color: #1ab4ab;
    &:hover {
      background-color: #169c94;
    }
  }
}

@media screen and (min-width: 1024px) and (min-device-width: 1024px) {
  .container .list-containter {
    .list-content {
      margin: 0 auto;
      max-width: 840px;
    }
  }
}
</style>
<style lang="scss">
.table {
  .el-tabs__item {
    font-size: 12px;
  }
}
.el-tabs__item.is-active,
.el-tabs__item:hover {
  color: #169c94;
}
.el-tabs__active-bar {
  background-color: #169c94;
}
</style>

<script>
import selectall from './selectall.js';
export default selectall;
</script>
